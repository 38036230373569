export default [
  {
   nannyID: "9406",
   location: "Lafayette, CO",
   age: "38",
   experience: "Current lead infant/toddler teacher. I love being a valued asset to others lives. Enriching the children with all the beauty of the world and little wonders. As well as providing gentle care and nurturing. Being close with families is so special to me.",
   vehicle: "Yes",
   payRate: "$25/hour+",
   availability: "Afterschool, Temp or Long Term"
  },
  {
   nannyID: "1728",
   location: "Boulder, CO",
   age: "32",
   experience: "For over 10 years, as a teacher and nanny, I've had a natural gift for forging authentic bonds with children and families that can be attested to by the children and families themselves foremost. I strive to engender goodness, fun, and adventure - aiming to share and guide experiences with children in this amazing world, with all the joys and growth it has to offer. I am a devoted social player- with love, humor, and understanding-in this world which has made me the fantastic teacher and nanny I am today.",
   vehicle: "Yes",
   payRate: "$25/hour+",
   availability: "ASAP, Part or Full Time, Temp or Long Term"
  },
  {
   nannyID: "4218",
   location: "Boulder, CO",
   age: "26",
   experience: "Nanny, Babysitter, Camp Counselor! Current My Boulder Nanny full time for one 3 year old girl, prior Temp nanny for My Boulder Nanny for 22 month old and 5 month old brothers! I have been a career nanny for the past 3 years now! I'm looking for my next family beginning at the end of August!",
   vehicle: "Yes",
   payRate: "$28/hour+",
   availability: "M-F, 8:30AM-4:30PM, ASAP, TEMP OR LONG TERM"
  },
  {
   nannyID: "6675",
   location: "Loveland, CO",
   age: "56",
   experience: "Current Overnight Newborn Nanny @ My Boulder Nanny, Doula, Lactation Consultation, Overnight Care, Part Time Nanny, Mom to adult children",
   vehicle: "Yes",
   payRate: "$28/hr+",
   availability: "ASAP, Temp Nanny"
 },
 {
   nannyID: "5335",
   location: "Franktown, CO",
   age: "21",
   experience: "I am a division 1 swimmer who has nannied kids for the past 5 years. I love to share my knowledge of not only the water, but education, life and social skills, and much more. It is my passion to take care of kids and help them grow into wonderful young adults.",
   vehicle: "Yes",
   payRate: "$20/hr+",
   availability: "Please email myboulderbabysitter@gmail.com to inquire"
 }
];
