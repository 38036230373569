import React, { useState } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/styles.scss";
import {
  faCalendarAlt,
  faCalendarDay,
  faDollarSign,
  faMapMarkerAlt,
  faCar,
  faSkiing,
} from "@fortawesome/free-solid-svg-icons";

const defaultImage = require("@images/mbb-logo-1-96x96.png");

export default ({
  nannyID,
  location,
  age,
  experience,
  vehicle,
  payRate,
  availability,
  interests,
  introduction,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const getTabStyles = (tab) => {
    if (currentTab === tab) {
      return "is-active";
    } else {
      return "";
    }
  };
  const overview = (
    <div>
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </span>
        <span className="has-text-weight-bold"> Location:</span>
      </div>
      <div>{location}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faCalendarDay} />
        </span>
        <span className="has-text-weight-bold"> Age:</span>
      </div>

      <div>{age}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faCar} />
        </span>
        <span className="has-text-weight-bold"> Vehicle:</span>
      </div>
      <div>{vehicle}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faDollarSign} />
        </span>
        <span className="has-text-weight-bold"> Pay Rate:</span>
      </div>
      <div>{payRate}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faCalendarAlt} />
        </span>
        <span className="has-text-weight-bold"> Availability:</span>
      </div>
      <div>{availability}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faSkiing} />
        </span>
        <span className="has-text-weight-bold"> Interests:</span>
      </div>
      <div>{interests}</div>
    </div>
  );
  const nannyIntro = (
    <div>
      <div className="has-text-weight-bold has-text-centered">
        Nanny Introduction
      </div>
      <div>{introduction}</div>
    </div>
  );
  const nannyExperience = (
    <div>
      <div className="has-text-weight-bold has-text-centered">
        Nanny's Past Experience
      </div>
      <div>{experience}</div>
    </div>
  );
  return (
    <div className="column is-6">
      <div className="card bm--card-equal-height">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img src={defaultImage} alt="Family image" />
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-4">Nanny #{nannyID}</p>
              <p className="subtitle is-6">{location}</p>
            </div>
          </div>
          <div className="tabs is-centered is-boxed">
            <ul>
              <li className={getTabStyles(0)}>
                <a onClick={() => setCurrentTab(0)}>Overview</a>
              </li>
              <li className={getTabStyles(1)}>
                <a onClick={() => setCurrentTab(1)}>Introduction</a>
              </li>
              <li className={getTabStyles(2)}>
                <a onClick={() => setCurrentTab(2)}>Experience</a>
              </li>
            </ul>
          </div>
          <div className="content">
            <div>{currentTab == 0 && overview}</div>
            <div>{currentTab == 1 && nannyIntro}</div>
            <div>{currentTab == 2 && nannyExperience}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
