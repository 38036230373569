import React from "react";
import Layout from "@components/layout/layout";
import NannyCard from "@components/nannyCard";
import nannies from "@data/nannies";
import "@styles/styles.scss";

export default () => {
  return (
    <Layout pageTitle="Nannies">
      <div style={{ height: "5vh" }}></div>
      <div className="section">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-12 has-text-centered">
              <h1 className="title">Nannies</h1>
            </div>
            <div style={{ height: "8vh" }}></div>
            {nannies.map((nanny) => {
              return (
                <NannyCard
                  key={nanny.nannyID}
                  nannyID={nanny.nannyID}
                  location={nanny.location}
                  age={nanny.age}
                  experience={nanny.experience}
                  vehicle={nanny.vehicle}
                  payRate={nanny.payRate}
                  availability={nanny.availability}
                  interests={nanny.interests}
                  introduction={nanny.introduction}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};
